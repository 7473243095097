import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import * as yup from "yup";
import { useFormik } from "formik";
import { TextField, MenuItem, Autocomplete, Select, Button } from "@mui/material";

import AedasLoading from "../../../Components/AedasLoading/AedasLoading";
import { useFetch } from "../../../Hooks/useFetch";

import {
  addNewUser,
	getAllUsers,
	removeUser
} from "../../../Services/UserService/UserService";


export const NewUserModal = (props: any) => {
    const [ selected, setSelected ] = useState<any>(null);
    const { setOpenEditModalShow } = props;

    return (
      <>
        <FormNewUserInternal
          setOpenEditModalShow={setOpenEditModalShow}
					setIsLoading={props.setIsLoading}
        ></FormNewUserInternal>
      </>
    );
  };
  
  const FormNewUserInternal = (props: any) => {
    const { setOpenEditModalShow } = props;
    const formik = useFormik({
      initialValues: {
        email: '',
      },

      validationSchema: null,
      
			onSubmit: (values: any) => {       
        addNewUser(values.email)
          .then(() => {
            toast.success("Usuario añadido");
            setOpenEditModalShow(false);
          })
          .catch(() => toast.error("Ha habido un error"));
      },
    });

    return (
      <form onSubmit={formik.handleSubmit}>
          <div className="p-4">
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                style={{width: "100%"}}
                InputLabelProps={{ shrink: true }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
          </div>
        <div style={{width: "fit-content", margin: "0 auto"}}>
          <Button type="submit" className="button blue-button">
            Crear usuario
          </Button>
        </div>
      </form>
    );
  };