import { FC, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export const BarChart: FC<any> = (props: any): any => {
  const sorter = (a: any, b: any) => {
    return a.localeCompare(b, "es")
  }


  const state: any = {
    series: props.data,
    options: {
      colors: ['#b1003b', '#87d0ad', '#00b176'],
      chart: {
        width: 380,
        type: "bar",
        stacked: true,
        stackType: '100%',
      },

      series: [
        {
          name: "KO",
          data: props.data.map((item: any) => item.KO),
        },
        {
          name: "OK con Error",
          data: props.data.map((item: any) => item.OK_Errors ?? 0),
        },
        {
          name: "OK",
          data: props.data.map((item: any) => item.OK),
        },
      ],

      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      
      stroke: {
        width: 1,
        colors: ['#fff']
      },    
      
      xaxis: {
        categories: props.data.map((item: any) => item.endpoint ?? 'Sin especificar').sort(sorter),
      },

      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + " requests"
          }
        }
      },

      fill: {
        opacity: 1
      },
      
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 40,
      }, 

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },

  };


  return (
    <div id="chart" style={{ width: "80%", margin: "0 auto" }}>
      <ReactApexChart
        options={state.options}
        series={state.options.series}
        type="bar"
      />
    </div>
  );
};
