import { FC, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

export const TimeBarChart: FC<any> = (props: any): any => {
console.log(props)
  const daysArray = Array.from({ length: props.monthDays }, (v, i) => i + 1);

  const processedData = daysArray.map((day: number) => {
    let data = {
      Ko: 0,
      Ok_with_errors: 0,
      Ok: 0
    };

    props.data.forEach((item: any) => {
      if (item.day == day) {
        data.Ko = item.KO;
        data.Ok_with_errors = item.OK_Errors; 
        data.Ok = item.OK; 
    }})

    return data;
  })

  const state: any = {
    series: props.data,
    options: {
      colors: ['#b1003b', '#87d0ad', '#00b176'],
      chart: {
        width: 380,
        type: "bar",
      },

      series: [
        {
          name: "KO",
          data: processedData.map((item: any) => item.KO),
        },
        {
          name: "OK con Error",
          data: processedData.map((item: any) => item.OK_Errors ?? 0),
        },
        {
          name: "OK",
          data: processedData.map((item: any) => item.OK),
        },
      ],

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      
      stroke: {
        width: 1,
        colors: ['transparent']
      },    

      dataLabels: {
        enabled: false
      },
      
      xaxis: {
        categories: daysArray,
      },

      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + " requests"
          }
        }
      },

      fill: {
        opacity: 1
      },
      
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 40,
      }, 

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };


  return (
    <div id="chart" style={{ width: "80%", margin: "0 auto"}}>
      <ReactApexChart
        options={state.options}
        series={state.options.series}
        type="bar"
      />
    </div>
  );
};
