import { useEffect, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";

import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { Box, Button, TextField } from "@mui/material";

import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Components/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../Components/ModalCustom/CustomModal";
import { LogDetails } from "../LogDetails/LogDetails";

import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import {
  getAllLogs,
  getFilterOptions,
} from "../../Services/Monitor/MonitorServices";

const validationSchema = yup.object({});

export const Git = () => {
  const [page] = useState(0);
  const [pageSize] = useState(100);

  const [servicesParams, setServicesParams] = useState({
    page: page,
    size: pageSize,
    from_date: moment().subtract(3, "days").format(),
    to_date: moment().format(),
    endpoints: ["insertCommits"],
    promocion: [],
    origen: [],
    url: [],
    statusCode: [],
    userName: [],
    service: [],
    uhnreg: [],
    idOpportunity: [],
    error: [], //lista de núm KO -> 1, OK -> 2, OK con error -> 3
  });

  const [filterOptions, isFetchingOptions] = useFetch(getFilterOptions, {
    tab: 1,
  });
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const [rowCountState, setRowCountState] = useState(pageSize);
  const statusOptions = ["OK", "OK Error", "KO"];

  useEffect(() => {
    if (selectedRow) {
      setOpenModalShow(true);
    }
  }, [selectedRow]);

  const formik = useFormik({
    initialValues: {
      fromDate: moment().subtract(3, "days").format("YYYY-MM-DD"),
      untilDate: moment().format("YYYY-MM-DD"),
      endpoints: servicesParams.endpoints,
      promocion: servicesParams.promocion,
      url: servicesParams.url,
      origen: servicesParams.origen,
      statusCode: servicesParams.statusCode,
      userName: servicesParams.userName,
      page: servicesParams.page,
      service: servicesParams.service,
      uhnreg: servicesParams.uhnreg,
      idOpportunity: servicesParams.idOpportunity,
      error: servicesParams.error,
    },

    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      setServicesParams({
        ...values,
        from_date: values.fromDate + "T00:00:00",
        to_date: values.untilDate + "T23:59:59",
        size: 100,
        service: values.service,
        error: values.service,
      });

      updateLogs();
    },
  });

  const [allLogs, isFetchingLogs, updateLogs] = useFetch(
    getAllLogs,
    servicesParams
  );

  useEffect(() => {
    if (allLogs) {
      setRowCountState(allLogs.total);
    }
  }, [allLogs]);

  const isLoading = useIsLoading([isFetchingLogs, isFetchingOptions]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      flex: 1,
    },
    {
      field: "endpoint",
      headerName: "Endpoint",
      type: "string",
      editable: false,
      flex: 2,
    },
    {
      field: "origin",
      headerName: "Origin",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "statusCode",
      headerName: "Status",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        const backgroundColor: any = {
          OK: "var(--aedas-green)",
          "OK Error": "var(--aedas-lightgreen)",
          KO: "var(--aedas-red)",
        };

        if (params.row.errorType) {
          return (
            <div
              className="status-button"
              style={{
                backgroundColor: `${backgroundColor[params.row.errorType]}`,
              }}
            >
              {params.row.errorType}
            </div>
          );
        } else {
          if (params.row.statusCode == 1) {
            return (
              <div
                className="status-button"
                style={{ backgroundColor: `${backgroundColor["OK"]}` }}
              >
                OK
              </div>
            );
          } else {
            return (
              <div
                className="status-button"
                style={{ backgroundColor: `${backgroundColor["KO"]}` }}
              >
                KO
              </div>
            );
          }
        }
      },
      editable: false,
      flex: 1,
    },
    {
      field: "time",
      headerName: "Time",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{moment(params.row.time).format("YYYY/MM/DD HH:mm")}</div>;
      },
      editable: false,
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{params.row.userName ?? "-"}</div>;
      },
      editable: false,
      flex: 2,
    },
    {
      field: "promotionID",
      headerName: "Promotion ID",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{params.row.promotionID ?? "-"}</div>;
      },
      editable: false,
      flex: 1,
    },
    {
      field: "uhnreg",
      headerName: "Uhnreg",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{params.row.uhnreg ?? "-"}</div>;
      },
      editable: false,
      flex: 1,
    },
  ];

  const handleResetFilter = () => {
    formik.resetForm();
    formik.handleSubmit();
  };

  const onCellClick = (e: any) => {
    if (allLogs && allLogs.items.length > 0) {
      setSelectedRow(allLogs.items?.find((log: any) => log.id === e.id));
    }
  };

  const handleNewPage = (e: any) => {
    formik.setFieldValue("page", e);
    formik.handleSubmit();
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <>
          <div className="h-100 m-4">
            <div className="main-container p-3" id="home">
              <h4 className="main-title mb-3">Monitor API</h4>

              <Box
                component="form"
                sx={{
                  width: "90%",
                  mx: "auto",
                  my: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
                noValidate
                autoComplete="on"
                className="text-center"
                onSubmit={formik.handleSubmit}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "60%",
                    }}
                  >
                    <TextField
                      id="fromDate"
                      label="Date from"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      style={{ margin: "1rem 1rem 1rem 0rem", width: "50%" }}
                      type={"date"}
                      value={formik.values.fromDate}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.fromDate &&
                        Boolean(formik.errors.fromDate)
                      }
                      helperText={
                        formik.touched.fromDate && formik.errors.fromDate
                      }
                    />
                    <TextField
                      id="untilDate"
                      label="Date until"
                      variant="outlined"
                      style={{ margin: "1rem 0", width: "50%" }}
                      InputLabelProps={{ shrink: true }}
                      type={"date"}
                      value={formik.values.untilDate}
                      inputProps={{
                        min: formik.values.fromDate,
                      }}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.untilDate &&
                        Boolean(formik.errors.untilDate)
                      }
                      helperText={
                        formik.touched.untilDate && formik.errors.untilDate
                      }
                    />
                  </div>

                  <div id="filter-buttons" className="button-container">
                    <Button
                      onClick={handleResetFilter}
                      color="primary"
                      className="button grey-button"
                    >
                      Restablecer
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="button blue-button"
                    >
                      Buscar
                    </Button>
                  </div>
                </div>
              </Box>

              <DataGridEditabled
                onlyEdit={false}
                showHeader={false}
                rows={allLogs?.items ?? []}
                columns={columns}
                onCellClick={(event: any) => onCellClick(event)}
                hideFooterPagination={false}
                rowCount={rowCountState}
                rowsPerPageOptions={[50]}
                pagination
                page={formik.values.page}
                pageSize={pageSize}
                onPageChange={handleNewPage}
              ></DataGridEditabled>

              <CustomModal
                size="xl"
                show={openModalShow}
                onChange={() => {
                  setOpenModalShow(!openModalShow);
                }}
                title="Detalle del Log"
                body={
                  <LogDetails
                    selectedRow={selectedRow}
                    setOpenModalShow={setOpenModalShow}
                    show={() => setOpenModalShow(!openModalShow)}
                  />
                }
              ></CustomModal>
            </div>
          </div>
        </>
      )}
    </>
  );
};
