import axios from "axios";
import { toast } from "react-toastify";

export const getAllLogs = (params: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  const body = {
    from_date: params.from_date,
    to_date: params.to_date,
    endpoints: params.endpoints,
    promocion: params.promocion,
    origen: params.origen,
    url: params.url,
    statusCode: params.statusCode,
    userName: params.userName,
    uhnreg: params.uhnreg,
    idOpportunity: params.idOpportunity,
    error: params.error,
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      `/cosmos/all?page=${params.page}&size=${params.size}`,
    body,
    config
  );
};

export const counterLogs = (params: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  const body = {
    from_date: params.from_date,
    to_date: params.to_date,
    endpoints: params.endpoints,
    promocion: params.promocion,
    origen: params.origen,
    url: params.url,
    statusCode: params.statusCode,
    userName: params.userName,
    diario: params.diario,
    uhnreg: params.uhnreg,
    idOpportunity: params.idOpportunity,
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/counterCosmos",
    body,
    config
  );
};

export const getFilterOptions = (params: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/optionsCosmos?tab=" + params.tab,
    config
  );
};

export const getYears = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(process.env.REACT_APP_API_BACKEND + "/yearsCosmos", config);
};
