import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";

import { GridColDef, GridRenderEditCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Button, TextField, Autocomplete } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';

import AedasLoading from '../../Components/AedasLoading/AedasLoading';
import DataGridEditabled from '../../Components/DataGridEditabled/DataGridEditabled';
import CustomModal from '../../Components/ModalCustom/CustomModal';
import { LogDetails } from '../LogDetails/LogDetails';

import { useFetch, useIsLoading } from '../../Hooks/useFetch';
import { getAllLogs, getFilterOptions } from '../../Services/Monitor/MonitorServices';

import './Home.css';

const validationSchema = yup.object({
});

export const Home = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const [ servicesParams, setServicesParams ] = useState({
      page: page,
      size: pageSize,
      from_date: moment().subtract(3, 'days').format(),
      to_date: moment().format(),
      endpoints: [],
      promocion: [],
      origen: [],
      url: [],
      statusCode: [],
      userName: [],
      service: ["KO"],
      uhnreg: [],
      idOpportunity: [],
      error: ["KO"], //lista de núm KO -> 1, OK -> 2, OK con error -> 3
    }
  )
 
  const [ rowsData, setRowsData ] = useState<any[]>([]);
  const [ filterOptions, isFetchingOptions ] = useFetch(getFilterOptions, {tab: 1});
  const [ openModalShow, setOpenModalShow ] = useState<boolean>(false);
  const [ selectedRow, setSelectedRow ] = useState<any>(null);
  const [ status, setStatus ] = useState<any>();

  const [rowCountState, setRowCountState] = useState(pageSize);
  const statusOptions = ["OK", "OK Error", "KO"];
    
  useEffect(() => {
    if (selectedRow) {
      setOpenModalShow(true);
    }
  }, [selectedRow]);

 
  const formik = useFormik({
    initialValues: {
      fromDate: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      untilDate: moment().format('YYYY-MM-DD'),
      endpoints: servicesParams.endpoints,
      promocion: servicesParams.promocion,
      url: servicesParams.url,
      origen: servicesParams.origen,
      statusCode: servicesParams.statusCode,
      userName: servicesParams.userName,
      page: servicesParams.page,
      service: servicesParams.service,
      uhnreg: servicesParams.uhnreg,
      idOpportunity: servicesParams.idOpportunity,
      error: servicesParams.error,
    },

    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      setServicesParams(
        {...values, 
          from_date: values.fromDate + "T00:00:00", 
          to_date: values.untilDate + "T23:59:59", 
          size: 100, 
          service: values.service,
          error: values.service,  
        })
    
      updateLogs();
    },
  });

  const [ allLogs, isFetchingLogs, updateLogs ] = useFetch(getAllLogs, servicesParams);
  
  useEffect(()=> {
    if ( allLogs ) {
      setRowCountState(allLogs.total);        
    }
  }, [allLogs])

  const isLoading = useIsLoading([isFetchingLogs, isFetchingOptions]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      flex: 1,
    },
    {
      field: "endpoint",
      headerName: "Endpoint",
      type: "string",
      editable: false,
      flex: 2,
    },
    {
      field: "origin",
      headerName: "Origin",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "statusCode",
      headerName: "Status",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        const backgroundColor: any = {
          "OK": "var(--aedas-green)",
          "OK Error": "var(--aedas-lightgreen)",
          "KO": "var(--aedas-red)"
        }

        if (params.row.errorType) {
          return (
            <div className="status-button" style={{ backgroundColor: `${backgroundColor[params.row.errorType]}`}}>
              { params.row.errorType }
            </div>
          );
        } else {
          if (params.row.statusCode == 1) {
            return (
              <div className="status-button" style={{ backgroundColor: `${backgroundColor["OK"]}`}}>
                OK
              </div>
            );
          } else {
            return (
              <div className="status-button" style={{ backgroundColor: `${backgroundColor["KO"]}`}}
              >
                KO
              </div>
            );
          }
        }

      },
      editable: false,
      flex: 1,
    },
    {
      field: "time",
      headerName: "Time",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
          return <div>{ moment(params.row.time).format("YYYY/MM/DD HH:mm") }</div>;
      },
      editable: false,
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{ params.row.userName ?? '-' }</div>;
      },
      editable: false,
      flex: 2,
    },
    {
      field: "promotionID",
      headerName: "Promotion ID",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{ params.row.promotionID ?? '-' }</div>;
      },
      editable: false,
      flex: 1,
    },
    {
      field: "uhnreg",
      headerName: "Uhnreg",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{ params.row.uhnreg ?? '-' }</div>;
      },
      editable: false,
      flex: 1,
    },
  ]

  const handleResetFilter = () => {
    formik.resetForm();
    formik.handleSubmit();
  }

  const onCellClick = (e: any) => {
    if (allLogs && allLogs.items.length > 0 ){
      setSelectedRow(allLogs.items?.find((log: any) => log.id === e.id));
    }
  };

  const handleNewPage = (e: any) => {
    formik.setFieldValue("page", e);
    formik.handleSubmit();
  }

  return (
    <>
      {isLoading ? ( <AedasLoading /> ) : (      
        <>
          <div className='h-100 m-4'>
            <div className='main-container p-3' id='home'>
              <h4 className='main-title mb-3'>Monitor API</h4>
            
              <Box
                  component="form"
                  sx={{
                    width: "90%",
                    mx: "auto",
                    my: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'stretch'
                  }}
                  noValidate
                  autoComplete="on"
                  className="text-center"
                  onSubmit={formik.handleSubmit}
                  >

                  <div style={{display: "flex", justifyContent: "space-between", margin: "1rem 0"}}>
                  
                    <Autocomplete
                      id="endpoints"
                      multiple
                      options={filterOptions?.endpoints.sort().filter((option: any) => option != null)}
                      onChange={(e, value) => {
                        formik.setFieldValue("endpoints", value);
                      }}
                      style={{ width: "50%", marginRight: "1rem"}}
                      
                      value={formik.values.endpoints.filter((option: any) => option != null) }
                      renderInput={(params) => (
                        <TextField
                        {...params}
                          label="Endpoint"
                          variant="outlined"
                          error={
                            formik.touched.endpoints && Boolean(formik.errors.endpoints)
                          }
                          helperText={formik.touched.endpoints && formik.errors.endpoints} 
                        />
                      )}
                    />

                    <Autocomplete
                      id="promocion"
                      multiple
                      options={filterOptions?.promocion.sort().filter((option: any) => option != null) }
                      onChange={(e, value) => {
                        formik.setFieldValue("promocion", value);
                      }}
                      style={{ width: "50%", marginLeft: "0"}}
                      value={formik.values.promocion}
                      renderInput={(params) => (
                        <TextField
                        {...params}
                          label="Promotion ID"
                          variant="outlined"
                          error={
                            formik.touched.promocion && Boolean(formik.errors.promocion)
                          }
                          helperText={formik.touched.promocion && formik.errors.promocion} 
                        />
                      )}
                    />

                  </div>

                  <div style={{display: "flex", justifyContent: "space-between", margin: "1rem 0"}}>
                    <Autocomplete
                        id="url"
                        multiple
                        options={filterOptions?.url.sort().filter((option: any) => option != null) }
                        onChange={(e, value) => {
                          formik.setFieldValue("url", value);
                        }}
                        style={{ width: "100%"}}
                        value={formik.values.url}
                        renderInput={(params) => (
                          <TextField
                          {...params}
                            label="URL"
                            variant="outlined"
                            error={
                              formik.touched.url && Boolean(formik.errors.url)
                            }
                            helperText={formik.touched.url && formik.errors.url} 
                          />
                        )}
                      />
                  </div>

                  <div style={{display: "flex", justifyContent: "space-between", margin: "1rem 0"}}>
                    <Autocomplete
                      id="origen"
                      multiple
                      options={filterOptions?.origin.sort().filter((option: any) => option != null)}
                      onChange={(e, value) => {
                        formik.setFieldValue("origen", value);
                      }}
                      style={{ width: "50%", marginRight: "1rem"}}
                      
                      value={formik.values.origen.filter((option: any) => option != null) }
                      renderInput={(params) => (
                        <TextField
                        {...params}
                          label="Origin"
                          variant="outlined"
                          error={
                            formik.touched.origen && Boolean(formik.errors.origen)
                          }
                          helperText={formik.touched.origen && formik.errors.origen} 
                        />
                      )}
                    />

                    <Autocomplete
                      id="ko-ok"
                      options={statusOptions}
                      multiple
                      onChange={(e, value) => {
                        formik.setFieldValue('service', value);
                        // En algún momento tal vez se quiera agregar que cuando se selecciona el status Ok ya se seleccione el statusCode 1
                        // Se tendría que agregar estas lineas
                        // if( value[0] == "OK") {
                        //   formik.setFieldValue("statusCode", ["1"])
                        // }
                        //lo mismo si quisieran seleccionar para el status KO, los códigos que nos indiquen
                      }}
                      style={{ width: "50%", marginRight: "1rem"}}
                      value={formik.values.service }
                      renderInput={(params) => (
                        <TextField
                        {...params}
                          label="Status"
                          variant="outlined"
                          error={
                            formik.touched.service && Boolean(formik.errors.service)
                          }
                          helperText={formik.touched.service && formik.errors.service} 
                        />
                      )}
                    />

                    <Autocomplete
                      id="statusCode"
                      multiple
                      options={filterOptions?.statusCode.sort().filter((option: any) => option != null)}
                      onChange={(e, value) => {
                        formik.setFieldValue("statusCode", value);
                      }}
                      style={{ width: "50%", margin: "0"}}
                      
                      value={formik.values.statusCode.filter((option: any) => option != null) }
                      renderInput={(params) => (
                        <TextField
                        {...params}
                          label="Status Code"
                          variant="outlined"
                          error={
                            formik.touched.statusCode && Boolean(formik.errors.statusCode)
                          }
                          helperText={formik.touched.statusCode && formik.errors.statusCode} 
                        />
                      )}
                    />
                  </div>

                  <div style={{display: "flex", justifyContent: "space-between", margin: "1rem 0"}}>
                    <Autocomplete
                        id="userName"
                        multiple
                        options={filterOptions?.userName.sort().filter((option: any) => option != null) }
                        onChange={(e, value) => {
                          formik.setFieldValue("userName", value);
                        }}
                        style={{ width: "50%", marginRight: "1rem"}}
                        value={formik.values.userName}
                        renderInput={(params) => (
                          <TextField
                          {...params}
                            label="User name"
                            variant="outlined"
                            error={
                              formik.touched.userName && Boolean(formik.errors.userName)
                            }
                            helperText={formik.touched.userName && formik.errors.userName} 
                          />
                        )}
                      />

                      <Autocomplete
                        id="uhnreg"
                        multiple
                        options={filterOptions?.uhnreg.sort().filter((option: any) => option != null).map((option: number) => option.toString()) }
                        onChange={(e, value) => {
                          formik.setFieldValue("uhnreg", value);
                        }}
                        style={{ width: "50%", marginRight: "1rem"}}
                        value={formik.values.uhnreg}
                        renderInput={(params) => (
                          <TextField
                          {...params}
                            label="Uhnreg"
                            variant="outlined"
                            error={
                              formik.touched.uhnreg && Boolean(formik.errors.uhnreg)
                            }
                            helperText={formik.touched.uhnreg && formik.errors.uhnreg} 
                          />
                        )}
                      />

                      <Autocomplete
                        id="idOpportunity"
                        multiple
                        options={filterOptions?.idOpportunity.sort().filter((option: any) => option != null) }
                        onChange={(e, value) => {
                          formik.setFieldValue("idOpportunity", value);
                        }}
                        style={{ width: "50%", margin: "0"}}
                        value={formik.values.idOpportunity}
                        renderInput={(params) => (
                          <TextField
                          {...params}
                            label="Opportunity ID"
                            variant="outlined"
                            error={
                              formik.touched.idOpportunity && Boolean(formik.errors.idOpportunity)
                            }
                            helperText={formik.touched.idOpportunity && formik.errors.idOpportunity} 
                          />
                        )}
                      />
                  
                  
                  </div>        

                  <div style={{display: "flex", justifyContent: "space-between", margin: "1rem 0"}}>
                    <div style={{display: "flex", justifyContent: "space-between", width: "60%"}}>
                      <TextField
                        id="fromDate"
                        label="Date from"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        style={{margin: "1rem 1rem 1rem 0rem", width: "50%"}}
                        type={"date"}
                        value={formik.values.fromDate}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.fromDate && Boolean(formik.errors.fromDate)
                        }
                        helperText={formik.touched.fromDate && formik.errors.fromDate}
                      />
                      <TextField
                        id="untilDate"
                        label="Date until"
                        variant="outlined"
                        style={{margin: "1rem 0", width: "50%"}}
                        InputLabelProps={{ shrink: true }}
                        type={"date"}
                        value={formik.values.untilDate}
                        inputProps={{
                          min: formik.values.fromDate,
                        }}
                        onChange={formik.handleChange}
                        error={formik.touched.untilDate && Boolean(formik.errors.untilDate)}
                        helperText={formik.touched.untilDate && formik.errors.untilDate}
                      />              
                    </div>
                    
                    <div id="filter-buttons" className="button-container" >
                      <Button
                        onClick={handleResetFilter}
                        color="primary"
                        className="button grey-button"
                      >
                        Restablecer
                      </Button>
                      <Button
                        type="submit"
                        // onClick={handleSubmitLogsByStatus}

                        color="primary"
                        className="button blue-button"
                      >
                        Buscar
                      </Button>
                    </div>
                  </div>
              </Box>


              <DataGridEditabled
                  onlyEdit={false}
                  showHeader={false}
                  rows={allLogs?.items ?? []}
                  columns={columns}
                  onCellClick={(event: any) => onCellClick(event)}
                  hideFooterPagination={false}      
                  rowCount={rowCountState}
                  rowsPerPageOptions={[50]}
                  pagination
                  page={formik.values.page}
                  pageSize={pageSize}
                  // // paginationMode="server"
                  onPageChange={handleNewPage}
              ></DataGridEditabled>

              <CustomModal
                size="xl"
                show={openModalShow}
                onChange={() => {
                  setOpenModalShow(!openModalShow);
                }}
                title="Detalle del Log"
                body={
                  <LogDetails
                    selectedRow={selectedRow}
                    setOpenModalShow={setOpenModalShow}
                    show={() => setOpenModalShow(!openModalShow)}
                  />
                }
              ></CustomModal>
            
            </div>
          </div>
        </> 
        )
      } 
    </> 
  )
}