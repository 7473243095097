import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import axios from "axios";

import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";



import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Components/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../Components/ModalCustom/CustomModal";

import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import {
  getAllUsers,
  removeUser,
  getUserByID,
  addNewUser,
} from "../../Services/UserService/UserService";



import { NewUserModal } from "./NewUserModal/NewUserModal";

import './Users.css';

export const Users = () => {
  const [ users, isFechingUsers, updateUsers] = useFetch(getAllUsers);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const isLoadingFecth = useIsLoading([isFechingUsers]);

  const [openEditModalShow, setOpenEditModalShow] = useState<boolean>(false);
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedRow) {
      setOpenModalShow(true);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (!openModalShow || !openEditModalShow) {
      updateUsers();
    }
  }, [openModalShow, openEditModalShow]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },

    {
      field: "display_name",
      headerName: "Nombre y apellido",
      type: "string",
      width: 200,
      editable: false,
      flex: 1,
    },

    // {
    //   field: "id_rol",
    //   headerName: "Rol",
    //   type: "string",
    //   renderCell: (params: GridRenderEditCellParams) => {
    //     const rol: any = rolOptions.find((r: any) => r.id === params.value);
    //     return <div>{rol ? rol.name : "Sin Rol asignado"}</div>;
    //   },
    //   width: 200,
    //   editable: true,
    //   flex: 1,
    // },

    {
      field: "email",
      type: "string",
      headerName: "Email",
      editable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "active",
      type: "string",
      headerName: "Estado",
      editable: false,
      width: 200,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams) => {

        const user = users.find((p: any) => p.id === params.id);
        if (user?.active) {
          return (
            <div
              style={{
                padding: "4px 12px",
                backgroundColor: "var(--aedas-green)",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Activado
            </div>
          );
        } else {
          return (
            <div
              style={{
                padding: "4px 12px",
                backgroundColor: "var(--aedas-red)",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Desactivado
            </div>
          );
        }
      },
    },
  ];

  const onCellClick = (e: any) => {
    if (users && users.length > 0 ){
      setSelectedRow(users?.find((p: any) => p.id === e.id));
    }
  };

  const handleAdd = (e: any) => {
    setOpenEditModalShow(!openEditModalShow);
  };

  if (isLoadingFecth) {
    return (
      <>
        <AedasLoading></AedasLoading>
      </>
    );
  }

  return (
    <div className="h-100 m-4">

      <div className="main-container p-3" id='users'>
          <h1 className="main-title mb-2">Gestión de usuarios</h1>
          <p className='text-center'>Desde aquí se podrían crear/editar los usuarios para el uso de esta app.</p>

          <CustomModal
            size="xl"
            show={openEditModalShow}
            onChange={() => {
              setOpenEditModalShow(!openEditModalShow);
            }}
            title="Nuevo Usuario"
            body={
              <NewUserModal
                selectedRow={selectedRow}
                setOpenEditModalShow={setOpenEditModalShow}
                show={() => setOpenEditModalShow(!openEditModalShow)}
                setIsLoading={() => setIsLoading(!isLoading)}
              />
            }
          />

          <CustomModal
            size="xl"
            show={openModalShow}
            onChange={() => {
              setOpenModalShow(!openModalShow);
            }}
            title="Editar Usuario"
            body={
              <EditUserModal
                selectedRow={selectedRow}
                setOpenModalShow={setOpenModalShow}
                show={() => setOpenModalShow(!openModalShow)}
              />
            }
          ></CustomModal>

          <DataGridEditabled
            showHeader={true}
            rows={users ?? []}
            columns={columns}
            onCellClick={(event: any) => onCellClick(event)}
            handleAdd={(event: any) => handleAdd(event)}
            singleIcon={true}
            addIcon={true}
      ></DataGridEditabled>
      </div>
    </div>
  )
}

const EditUserModal = (props: any) => {
  const [ isLoading, setisLoading ] = useState(false);

  const formik = useFormik({
    initialValues: {
      active: props.selectedRow.active,
    },

    validationSchema: null,

    onSubmit: (values) => {
      setisLoading(true);
      
      const value = {
        id: props.selectedRow.id,      
        active: values.active, 
      };

      removeUser(value.id)
        .then(() => {
          toast.success("Guardado");
            setisLoading(false);
            props.setOpenModalShow(false);
        })
        .catch(() => {
          setisLoading(false);
          toast.error("Ha habido un error");
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form_new_user_internal">
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }} className=" p-4">
          <p style={{marginBottom: "0"}}>
            Estás editando al usuario 
            <strong> {props.selectedRow.display_name}</strong> 
          </p>
          
          <FormControlLabel
            control={
              <Checkbox
                value={formik.values.active}
                // @ts-ignore
                defaultChecked={formik.values.active}
                onChange={formik.handleChange}
              />
            }
            label="Activado"
            id="active"
            name="active"
          />
        </div>
      </div>
      
      <div style={{width: "fit-content", margin: "0 auto"}}>
        <LoadingButton type="submit" className="button blue-button" loading={isLoading}>
          {isLoading ? "" : "Guardar"}
        </LoadingButton>
      </div>

    </form>
  );
}
