
import React, { useState, useEffect } from "react";
import moment from "moment";

import { useFormik, validateYupSchema } from "formik";
import { Box, Button, TextField, Autocomplete } from "@mui/material";

import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import { BarChart } from "./StatisticsCharts/BarChart";
import { TimeBarChart } from "./StatisticsCharts/TimeBarChart";

import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import { counterLogs, getYears } from '../../Services/Monitor/MonitorServices';

import "./Statistics.css";

export const Statistics = (props: any) => {
  const [servicesParams, setServicesParams] = useState({
    from_date: moment().subtract(7, 'days').format(),
    to_date: moment().format(),
    endpoints: [],
    promocion: [],
    origen: [],
    url: [],
    statusCode: [],
    userName: [],
    diario: false
  })
  

  const [ allLogs, isFetchingLogs, updateLogs] = useFetch(counterLogs, servicesParams);
  const [ logsSumary, setLogsSumary ] = useState({ total : 0, KO: 0, OKWithError: 0, OK: 0 }) 
  const [ buttonSelected, setButtonSelected ] = useState(''); 

  const isLoading = useIsLoading([isFetchingLogs]);
  
  const formik = useFormik({
    initialValues: {
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      untilDate: moment().format('YYYY-MM-DD'),
    },

    validationSchema: null,

    onSubmit: async (values: any) => {
      const from_date = values.fromDate.includes('T') ? values.fromDate : `${values.fromDate}T00:00:00`;
      const to_date = values.untilDate.includes('T') ? values.untilDate : `${values.untilDate}T23:59:59`;

      setServicesParams({...servicesParams, from_date: from_date, to_date: to_date})
      updateLogs();
    },
  });
 
  useEffect(() => {
    if( allLogs ) {
      setLogsSumary(getLogSumary(allLogs));
    }
  }, [allLogs])

  useEffect(() => {
    if (buttonSelected !== '') {
      const button = document.querySelector(`#${buttonSelected}`);
      if (button) button.classList.add('clicked')
    }
  }, [isLoading])
  
  const getLogSumary = (logList: any[]) => {
    const totalKO = logList.reduce((accKO: number, object: any) => { return accKO + object.KO }, 0);
    const totalOKWithError = logList.reduce((accOKWithError: number, object: any) => { 
      const current = object.OK_Errors ?? 0; 
      return accOKWithError + current 
    }, 0);    
    const totalOK = logList.reduce((accOK: number, object: any) => { return accOK + object.OK }, 0);    
    
    return { total : totalKO + totalOK + totalOKWithError, KO: totalKO, OKWithError: totalOKWithError, OK: totalOK }
  }

  const handleQuickQuery = (e: any ) => {
    switch (e.target.id) {
      case 'day' :
        formik.setFieldValue('fromDate', moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setButtonSelected('day');
        break
        
      case 'week' :
        formik.setFieldValue('fromDate', moment().subtract(7, 'days').format('YYYY-MM-DD'));
        setButtonSelected('week');
        break
        
      case 'month' :
        formik.setFieldValue('fromDate', moment().subtract(30, 'days').format('YYYY-MM-DD'));
        setButtonSelected('month');
        break   
    }

    formik.setFieldValue('untilDate', moment().format('YYYY-MM-DD'));
    formik.handleSubmit();
  }

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <div className='h-100 m-4'>
      <div className='main-container p-3' id='statistics'>
        <h4 className='main-title mb-3'>Estadísticas de llamadas realizadas</h4>

        <div className="statistics-container">
          <Box
            component="form"
            sx={{
              width: "90%",
              mx: "auto",
              my: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: 'stretch'
            }}
            noValidate
            autoComplete="on"
            className="text-center"
            onSubmit={formik.handleSubmit}
            >

            <div id="quick-query-buttons" style={{display: "flex", justifyContent: "space-between", marginBottom: "2rem"}}>
              <Button
                color="primary"
                id="day"
                className="button blue-button"
                style={{width: "40%"}}
                onClick={handleQuickQuery}
              >
                últimas 24h
              </Button>
              <Button
                color="primary"
                id="week"
                className="button blue-button"
                style={{width: "40%"}}
                onClick={handleQuickQuery}
              >
                últimos 7 días
              </Button>
              <Button
                color="primary"
                id="month"
                className="button blue-button"
                style={{width: "40%"}}
                onClick={handleQuickQuery}
              >
                últimos 30 días
              </Button>
            </div>
            
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{display: "flex", justifyContent: "space-between", width: "70%"}}>
                <TextField
                  id="fromDate"
                  label="Procesos desde el"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  style={{margin: "1rem 1rem 1rem 0rem", width: "50%"}}
                  type={"date"}
                  value={formik.values.fromDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fromDate && Boolean(formik.errors.fromDate)
                  }
                  helperText={formik.touched.fromDate && formik.errors.fromDate}
                />
                <TextField
                  id="untilDate"
                  label="Procesos hasta el"
                  variant="outlined"
                  style={{margin: "1rem", width: "50%"}}
                  InputLabelProps={{ shrink: true }}
                  type={"date"}
                  value={formik.values.untilDate}
                  inputProps={{
                    min: formik.values.fromDate,
                  }}
                  onChange={formik.handleChange}
                  error={formik.touched.untilDate && Boolean(formik.errors.untilDate)}
                  helperText={formik.touched.untilDate && formik.errors.untilDate}
                />
              </div>
              
              <div id="filter-buttons" className="button-container" style={{width: "30%", margin: "0 auto"}}>                
                <Button
                  type="submit"
                  color="primary"
                  className="button blue-button"
                >
                  Buscar
                </Button>
              </div>
            </div>
          </Box>

          <div className="graphs">
            <p className="m-0 text-center">
              Distribución de Nº de llamadas realizados entre el 
              <strong> {moment(`${servicesParams.from_date}`).format('DD/MM/YYYY')}</strong> y el 
              <strong> {moment(`${servicesParams.to_date}`).format('DD/MM/YYYY')}</strong>.
            </p>
            
            <p className="m-0 text-center"> 
                <span>Total de llamadas </span><strong>{logsSumary.total} </strong>
                  <span>de las cuales KO:</span> 
                  <strong>{` ${logsSumary.KO} (${Math.round(logsSumary.KO / logsSumary.total * 100)}%)`}</strong>
                  <span>, KO con errores:</span> 
                  <strong>{` ${logsSumary.OKWithError} (${Math.round(logsSumary.OKWithError / logsSumary.total * 100)}%)`} </strong>  
                  <span>y OK: </span>
                  <strong>{` ${logsSumary.OK} (${Math.round(logsSumary.OK / logsSumary.total * 100)}%)`}</strong>  
            </p>

            <BarChart data={allLogs}/>

          </div>
        </div>
      </div>


      <div className='main-container p-3 mt-5' id='evolutions-statistics'>
        <h4 className='main-title mb-3'>Evolución mensual por servicio</h4>

        <div className="statistics-container">
          <EndpointEvolucion 
            servicesParams={servicesParams}
            updateLogs={updateLogs}
            getLogSumary={getLogSumary}
          />
        </div>
      </div> 
    
    </div>
  );
};

const EndpointEvolucion = (props: any) => {
  const [ servicesParams, setServicesParams ] = useState({
    ...props.servicesParams, 
    from_date: `${moment().format('YYYY')}-${moment().format('MM')}-01T00:00:00`,
    to_date: `${moment().format('YYYY-MM-DD')}T23:59:59`,
    diario: true
  });
  const [ allLogsDaily, isFetchingLogs, updateLogsDaily ] = useFetch(counterLogs, servicesParams);
  const [ dataBarChart, setDataBarChart ] = useState<any[]>([]);
  const [ logsSumary, setLogsSumary ] = useState({ total : 0, KO: 0, OKWithError: 0, OK: 0 }) 
  const [ endpointsOptions, setEndpointOptions ] = useState<any[]>([]);

  const [ yearsOptions, isFetchingyears ] = useFetch(getYears);
  const isLoading = useIsLoading([isFetchingyears, isFetchingLogs]);

  const monthOptions = [
    { name: 'Enero',
      id: 1,
      last_day: 31,
    },
    { name: 'Febrero',
      id: 2,
      last_day: 28,
    },
    { name: 'Marzo',
      id: 3,
      last_day: 31,
    },
    { name: 'Abril',
      id: 4,
      last_day: 30,
    },
    { name: 'Mayo',
      id: 5,
      last_day: 31,
    },
    { name: 'Junio',
      id: 6,
      last_day: 30,
    },
    { name: 'Julio',
      id: 7,
      last_day: 31,
    },
    { name: 'Agosto',
      id: 8,
      last_day: 31,
    },
    { name: 'Septiembre',
      id: 9,
      last_day: 30,
    },
    { name: 'Octubre',
      id: 10,
      last_day: 31,
    },
    { name: 'Noviembre',
      id: 11,
      last_day: 30,
    },
    { name: 'Diciembre',
      id: 12,
      last_day: 31,
    },
  ];

  const formik = useFormik({
    initialValues: {
      endpoint: endpointsOptions[0],
      month: monthOptions.find((month: any) => month.id == moment().format('MM')),
      year: moment().format('YYYY'),
    },

    validationSchema: null,

    onSubmit: async (values: any) => {
      const params = {
        from_date: `${values.year}-${values.month.id}-01T00:00:00`,
        to_date: `${values.year}-${values.month.id}-${values.month.last_day}T23:59:59`
      }
      setServicesParams({...servicesParams, from_date: params.from_date, to_date: params.to_date})
      updateLogsDaily();
    },
  });

  useEffect(() => {
    updateLogsDaily();
  }, [servicesParams])

  useEffect(()=> {
    if ( allLogsDaily ) {     

      const endpointList = allLogsDaily.map((log: any, index: number) => {
        if (allLogsDaily.at(index - 1).endpoint !== log.endpoint ) {
          return log;
        } 
      }).filter((log: any) => log !== undefined);    
    
      const key = 'endpoint';
      const unique = [...new Map(endpointList.map((item: any) => [item[key], item])).values()]
      const sorter = (a: any, b: any) => {
        return a.endpoint.localeCompare(b.endpoint, "es")
      }
      
      setEndpointOptions(unique.sort(sorter));
    }
      
  }, [allLogsDaily])

  useEffect(()=> {
    if (formik.values.endpoint) {
      let filterByEndpointLogs = allLogsDaily.filter((item: any) => item.endpoint == formik.values.endpoint.endpoint); 
      let parseDayAsNumber = filterByEndpointLogs.map((item: any) => parseInt(item.day));

      setDataBarChart(parseDayAsNumber);
      setLogsSumary(props.getLogSumary(filterByEndpointLogs))
    }

  }, [allLogsDaily, formik.values.endpoint])

  const comparator = (a: any, b: any) => {
    if (a.endpoint < b.endpoint ) return -1;
    if (a.endpoint > b.endpoint ) return 1;
    return 0
  }

  const handleOnChange = (e: any, value: any) => {
    if ( e.target.id.includes('month') ) {
      formik.setFieldValue("month", value);
    } else {
      formik.setFieldValue("year", value);
    }
    
    formik.handleSubmit();
  }

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <>
      <div id="endpoint-evolution">
        <Box
          component="form"
          sx={{
            width: "90%",
            mx: "auto",
            my: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: 'stretch'
          }}
          noValidate
          autoComplete="on"
          className="text-center"
          onSubmit={formik.handleSubmit}
        >

          <div style={{display: "flex", justifyContent: "space-between", margin: "1rem 0", alignItems: "center"}}>          
          <Autocomplete
              id="endpoint"
              options={endpointsOptions}
              onChange={(e, value) => {
                formik.setFieldValue("endpoint", value);
              }}
              style={{ width: "50%", marginRight: "1rem"}}
              getOptionLabel={(option: any) => option.endpoint}
              isOptionEqualToValue={(option, value) => option.endpoint === value.endpoint}
              defaultValue={formik.values.endpoint}
              renderInput={(params) => (
                <TextField
                {...params}
                  label="Endpoint"
                  variant="outlined"
                  error={
                    formik.touched.endpoint && Boolean(formik.errors.endpoint)
                  }
                  helperText={formik.touched.endpoint && formik.errors.endpoint} 
                />
              )}
            />
            
            <Autocomplete
              id="month"
              options={monthOptions}
              onChange={(e, value) => {
                handleOnChange(e, value);
              }}
              style={{ width: "50%", marginRight: "1rem"}}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              defaultValue={formik.values.month}
              renderInput={(params) => (
                <TextField
                {...params}
                  label="Mes"
                  variant="outlined"
                  error={
                    formik.touched.month && Boolean(formik.errors.month)
                  }
                  helperText={formik.touched.month && formik.errors.month} 
                />
              )}
            />

            <Autocomplete
              id="year"
              options={yearsOptions}
              onChange={(e, value) => {
                handleOnChange(e, value);
              }}
              style={{ width: "50%", marginLeft: "1rem"}}
              value={formik.values.year}
              renderInput={(params) => (
                <TextField
                {...params}
                  label="Año"
                  variant="outlined"
                  error={
                    formik.touched.year && Boolean(formik.errors.year)
                  }
                  helperText={formik.touched.year && formik.errors.year} 
                />
              )}
            />
          </div>
        </Box> 

        { dataBarChart.length > 0 && formik.values.endpoint ?
           ( 
              <>
                <p className="m-0 mb-2 text-center"> 
                  <span>Servicio </span>
                  <strong style={{textTransform: "uppercase"}}> {formik.values.endpoint?.endpoint} </strong>
                  <span>| Total de llamadas </span>
                  <strong>{logsSumary.total} </strong>
                  <span>de las cuales KO:</span> 
                  <strong>{` ${logsSumary.KO} (${Math.round(logsSumary.KO / logsSumary.total * 100)}%)`}</strong>  
                  <span>, OK con errores:</span> 
                  <strong>{` ${logsSumary.OKWithError} (${Math.round(logsSumary.OKWithError / logsSumary.total * 100)}%)`} </strong>  
                  <span>y OK: </span>
                  <strong>{` ${logsSumary.OK} (${Math.round(logsSumary.OK / logsSumary.total * 100)}%)`}</strong>  
                </p>
                <TimeBarChart data={dataBarChart} monthDays={formik.values.month?.last_day || 30} />
              </>
           ) :
           (<div>
            <p className="m-3 text-center">
              { !formik.values.endpoint ? 'Selecciona un endpoint para poder visualizar su evolución mensual' 
              : logsSumary.total == 0 ? 'No contamos con registros de este servicio para la fecha seleccionada' : ''}
            </p>
           </div> )
        }

      </div>
    </>
  )

}